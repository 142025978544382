import { useQuery } from '@tanstack/react-query';
import { GetFamilyApprovalType } from '../type/twoFactor';
import { familyApprovalApi } from 'service/api/familyApprovalApi';


const getFamilyApproval = async (payload: GetFamilyApprovalType) => {
  const familyApprovalService = familyApprovalApi();

  const response = await familyApprovalService.getFamilyApproval(payload);
  return response.data;
};

export const useGetFamilyApproval = (customerPersonId?: string, isEnabled = false) => {
  return useQuery({
    queryKey: ['familyApproval', customerPersonId],
    queryFn: () =>
      getFamilyApproval({ customerPersonId }),
    enabled: !!customerPersonId && isEnabled,
  });
};
