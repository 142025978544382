import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BadRequestError, CustomError } from '@dvag/dfs-api-client/error';
import { useNotification } from '../utils/notification/NotificationContext';
import { familyApprovalApi } from '../service/api/familyApprovalApi';
import { MultipleFamilyApprovalType } from '../type/twoFactor';


export const useFamilyApprovalPersonCentric = () => {
  const { showError } = useNotification();
  const queryClient = useQueryClient();
  const familyApprovalService = familyApprovalApi();

  return useMutation<
    Awaited<ReturnType<typeof familyApprovalService.sendMultipleFamilyApproval>>,
    CustomError,
    MultipleFamilyApprovalType,
    unknown
  >({
    mutationFn: async (payload: MultipleFamilyApprovalType) => {
      if (!payload.data || payload.data.length === 0) {
        throw new BadRequestError('No person approval data provided');
      }

      if (!payload.householdId || !payload.customerPersonId) {
        throw new BadRequestError('Invalid householdId or customerPersonId');
      }

      return familyApprovalService.sendMultipleFamilyApproval(payload);
    },
    onSuccess: async (_payloadSuccess, payload) => {
      await queryClient.invalidateQueries({
        queryKey: [`familyApproval-person-centric-${payload.householdId}-${payload.customerPersonId}`],
      });
    },
    onError: (err: CustomError) => {
      showError({ status: err?.status });
    },
  });
};
