import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BadRequestError, CustomError } from '@dvag/dfs-api-client/error';
import { FamilyApprovalType } from '../type/twoFactor';
import { useNotification } from '../utils/notification/NotificationContext';
import { familyApprovalApi } from '../service/api/familyApprovalApi';

export const useFamilyApproval = () => {
  const { showError } = useNotification();
  const queryClient = useQueryClient();
  const familyApprovalService = familyApprovalApi();

  return useMutation<
    Awaited<ReturnType<typeof familyApprovalService.sendFamilyApproval>>,
    CustomError,
    FamilyApprovalType,
    unknown
  >({
    mutationFn: async (payload: FamilyApprovalType) => {
      if (
        typeof payload.householdId === 'undefined' ||
        typeof payload.customerPersonId === 'undefined'
      ) {
        throw new BadRequestError('Invalid householdId');
      }

      const response = await familyApprovalService.sendFamilyApproval(payload);

      return {
        ...response,
      };
    },
    onSuccess: async (_payloadSuccess, payload) => {
      await queryClient.invalidateQueries({
        queryKey: [`familyApproval-${payload.householdId}-${payload.customerPersonId}`],
      });
    },
    onError: (err: CustomError) => {
      showError({ status: err?.status });
    },
  });
};
