import APIClient from '@dvag/dfs-api-client';
import { getContext, getToken } from '@dvag/dfs-orchestrator-client';

import {
  FamilyApprovalByPersonType,
  MultipleFamilyApprovalType,
  FamilyApprovalType,
  GetFamilyApprovalType,
  PersonFAData,
} from 'type/twoFactor';
import { getEnvironmentApiUrls } from '@dvag/dfs-constant-config/api-urls';
import { versions } from './versions';

const config = { baseURL: `${getEnvironmentApiUrls().recap}/${versions.v2}` };
const api = new APIClient({
  config,
  options: {
    getToken,
    getContext,
    hasDefaultRequestInterceptors: true,
  },
});

export const familyApprovalApi = () => {
  const sendFamilyApproval = async (payload: FamilyApprovalType, signal?: AbortSignal) =>
    api.post<FamilyApprovalType>(
      `/family-approval/${payload.householdId}/person/${payload.customerPersonId}/family-approval`,
      {
        approvalDuration: payload.duration.value,
      },
      { signal },
    );


  const sendFamilyApprovalByPerson = async (payload: FamilyApprovalByPersonType, signal?: AbortSignal) =>
    api.post<FamilyApprovalType>(
      `/family-approval/household/${payload.householdId}/client/${payload.customerPersonId}/single-approval`,
      {
        approvalDuration: payload.duration.value,
        approvedClientId: payload.selectedPersonId,
      },
      { signal },
    );

  const sendMultipleFamilyApproval = async (payload: MultipleFamilyApprovalType, signal?: AbortSignal) =>
    api.post<FamilyApprovalType>(
      `/family-approval/household/${payload.householdId}/client/${payload.customerPersonId}/multiple-approval`,
      {data: payload.data},
      { signal },
    );

  const getFamilyApproval = async (payload: GetFamilyApprovalType) =>
    api.get<PersonFAData[]>(
      `/family-approval/client/${payload.customerPersonId}/households/persons`
    );

  return {
    sendFamilyApproval,
    getFamilyApproval,
    sendFamilyApprovalByPerson,
    sendMultipleFamilyApproval
  };
};