import { PersonInHouseholdDto } from '../graphql/person/generates';

export type SharingPeriodType = 'SEVEN_DAYS' | 'THIRTY_DAYS' | 'SIX_MONTHS' | 'UNLIMITED' | 'NICHT_FREIGEBEN';

export enum PeriodEnum {
  SEVEN_DAYS = 'SEVEN_DAYS',
  THIRTY_DAYS = 'THIRTY_DAYS',
  SIX_MONTHS = 'SIX_MONTHS',
  UNLIMITED = 'UNLIMITED',
  NICHT_FREIGEBEN = 'NICHT_FREIGEBEN'
}

export interface ISharingPeriod {
  value: SharingPeriodType;
  label: string;
}

export interface FamilyApprovalType {
  duration: ISharingPeriod;
  householdId?: string;
  customerPersonId?: string;
}

export interface FamilyApprovalByPersonType {
  duration: ISharingPeriod;
  selectedPersonId?: string;
  householdId?: string;
  customerPersonId?: string;
}

interface PersonApprovalDetails {
  approvalDuration: SharingPeriodType;
  approvedClientId: string;
}

export interface MultipleFamilyApprovalType {
  data: PersonApprovalDetails[] | undefined;
  householdId?: string;
  customerPersonId?: string;
}

export interface GetFamilyApprovalType {
  customerPersonId?: string;
}

export interface PersonFAData {
  familyApprovalStatus: string;
  householdId: string;
  personClientId: string;
  personFirstName: string;
  personLastName: string;
  personRegistered: boolean;
  personSalutation: string;
  familyApprovalDueDate?: string;
}

export interface FamilyApprovedPersonDto extends PersonInHouseholdDto {
  familyApprovalStatus?: string;
  familyApprovalDueDate?: string;
  personRegistered?: boolean;
  sharingPeriod?: ISharingPeriod;
}

export interface FamilyApprovalTwoFAMutationType {
  personListOlderEighteen: FamilyApprovedPersonDto[];
  duration: ISharingPeriod;
  customerPersonId?: string;
  keycloakId?: string;
  householdId?: string;
  advisorId?: string;
}
export interface KundanzuganHaushaltDetailResponse {
  keycloakId: string;
  kundennummer: string;
  vorname: string;
  nachname: string;
  anwendungen: string[];
  registrierungsstatus: string;
  kundenzugang: string[];
  einwilligungVorhanden: boolean;
  haushaltrangfolge: number;
}

export interface FamilyApprovalTwoFAType {
  members: string[];
  duration: string;
  keycloakId?: string;
}

export interface KundanzuganHaushaltDetailType {
  householdId?: string;
  advisorId?: string;
}

export interface KundanzuganTransactionStatusType {
  transactionId: string;
}

export interface KundanzuganTransactionStatusResponse {
  status: 'OFFEN' | 'AKZEPTIERT' | 'ABGELEHNT';
}
